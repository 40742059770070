@use 'sass:meta';
@import '_functions';
@import './style/link-colors';
@import './style/normalize';
@import './style/utils';
@import './style/mat-theme';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './assets/chase-icons.css';
@import './style/mixin';
@import './style/components/index';
@import './style/overrides/index';

@import './style/variables';
@import './style/breakpoints';
@import './style/animations';
@import './style/typography';
@import './style/reset';
@import './style/body/index';

@import 'mixin';

.u-tooltip.restricted {
  & > .p-tooltip-text {
    width: 198px;
    position: relative;
    right: 30px;
  }
}

overlay.oauth {
  .bottom-note {
    @include respond-to-height('small') {
      margin-top: 8px;
    }
  }
}

.highlight {
  color: var(--color-text-primary);
  font-weight: bold;
}

body {
  background-color: var(--color-bg);
  &[window-name='signin'][env='native'] {
    // todo implment env in css
    padding: var(--window-padding-top) var(--window-padding-right) var(--window-padding-bottom) var(--window-padding-left);
    overflow: hidden;
    .window {
      border: 1px solid var(--color-border);
      border-radius: 6px;
      height: var(--signin-height);
      width: var(--signin-width);
    }
  }

  // Those are used in Unleash redundant feature
  &.maximized,
  &.no-shadow {
    padding: 0;

    .window {
      box-shadow: none;
      border: none;
    }
  }

  .window {
    &.bar {
      width: 100vw;
      height: 100vh;
    }

    &.developer {
      height: 100vh;
    }
  }
}

.window {
  box-shadow: var(--window-shadow);
  width: 100%;
  position: relative;
}

.panel {
  border: 1px solid var(--color-border);
  width: 100%;
  max-width: 1020px;
  box-shadow: var(--window-shadow);
}

.text-highlight {
  color: var(--gray-900);
  background-color: #ffdda8;
}

.text-bold-highlight {
  font-weight: 700;
}

.result-header-highlight {
  color: var(--color-text-primary);
}

.autocomplete-highlight {
  color: var(--color-text-primary);
}

.text-highlight-light {
  color: #b1dcff;
}

.btn {
  min-width: 72px;
  min-height: 32px;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-text-primary);
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  margin: 0 8px;
  padding: 9px 12px;
  outline: none;
  -webkit-app-region: no-drag;
  text-transform: capitalize;

  &.icon-btn {
    font-size: 11px;
    margin: 0;
    padding: 10px 12px;
    min-width: auto;
    height: auto;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.primary {
    color: var(--color-primary);
  }

  &.raised {
    color: #fff;
    border-radius: 4px;

    &.mini {
      min-height: 28px;
      padding: 0 8px;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 1;

      i {
        font-size: 12px;
      }
    }

    &.primary {
      border: solid 1px var(--color-primary);
      background-color: #0279d7;

      &.gradient {
        border: none;
        background: linear-gradient(225deg, var(--color-primary) 0%, #005da8 100%);
        box-shadow: 0 3px 4px 0 rgba(28, 28, 32, 0.75);
        opacity: 0.93;
      }
    }

    &.warn {
      border: solid 1px #ff0035;
      background-color: rgba(255, 0, 53, 0.2);
    }
  }

  &:not(.icon-btn) i {
    font-size: 16px;
    padding-right: 7px;
  }

  & .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &:not([disabled]):hover {
    filter: brightness(1.1);
  }

  &:disabled {
    filter: grayscale(1) !important;
    box-shadow: none !important;
  }
}

.overlay-blur {
  filter: blur(7px);
}

[class='*-backdrop'] {
  -webkit-app-region: drag;

  > * {
    -webkit-app-region: no-drag;
  }
}
.clear-backdrop {
  background-color: transparent;
  padding: 12px 16px;
  border-radius: 0 0 6px 6px;
}

.walkthrough-backdrop {
  background: var(--color-bg-overlay);
  padding: 12px 16px;
  border-radius: 0 0 6px 6px;
}

.blur-5-backdrop {
  backdrop-filter: blur(4px);
  background: var(--color-bg-backdrop-5);
  border: 1px solid transparent;
}

.blur-2-backdrop {
  background: var(--color-bg-backdrop-2);
}

.blur-1-backdrop {
  background: var(--color-bg-backdrop-1);
}

// TODO: consider new component
.confirm {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(35, 35, 41, 0) 0%, rgba(35, 35, 41, 0.8) 49.35%, #232329 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadein 0.3s linear forwards;

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {
    color: var(--color-text-primary);
    font-size: 13px;
    font-weight: 500;
    margin-right: 12px;
  }

  .confirm-btn,
  .cancel-btn {
    padding: 7px 8px;
    min-width: 60px;
    margin: 0 6px;
    opacity: 0.93;
    box-shadow: 0 3px 4px 0 rgba(2, 18, 30, 0.3);
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
    display: flex;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    &:hover {
      filter: brightness(1.2);
    }

    i {
      padding-right: 4px;
      color: var(--color-text-primary);
    }
  }

  .confirm-btn {
    background-color: #16b783;
  }

  .cancel-btn {
    background-color: rgba(228, 31, 72, 0.5);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Unless we expose bar style capsulation (which we want to avoid) this styling need to stay here.
// Used for the 'tags' inside the bar placeholder.
*:focus-within .placeholder b {
  background: unset;
}
* .placeholder b {
  background: unset !important;
  -webkit-app-region: no-drag;
  @include add-overlay(1, 4px);
}

.key-button,
.placeholder,
.pro-tip {
  b {
    --button-size: 22px;
    font-weight: 400;
    background: var(--color-bg-tertiary);
    border: 0.5px solid var(--color-border);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0px 6px;
    color: var(--color-text-secondary);
    min-width: var(--button-size);
    min-height: var(--button-size);
    font-size: inherit;
    display: inline-block;
    text-align: center;
    vertical-align: baseline;
    margin: 0 8px;
    line-height: 18px;
    pointer-events: all;
    transition: all 0.2s;

    &.clickable {
      cursor: pointer;
      &:hover {
        border: 1px solid var(--color-border-active);
        @include add-overlay(8, 4px);
      }
    }

    @include respond-to-width('extra-small') {
      --button-size: 16px;
    }
  }

  &-label {
    text-transform: uppercase;
    color: var(--color-primary);
    margin-right: 8px;
    font-weight: 500;
  }
}

.placeholder b {
  font-size: 14px;
}

.ui-draggable {
  -webkit-app-region: no-drag;
}

.no-pointer-events * {
  pointer-events: none !important;
}

html:not([has-native-shadow]) .backdrop-svg {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}
.backdrop-svg {
  @include size(100%);
  top: 0;
  left: 0;
  position: absolute;

  border-radius: 6px;
  z-index: 999999;
  transition: all 0.5s;
}

.masked {
  mask: url('#backdrop-mask');
  width: 100%;
  height: 100%;
  fill: var(--color-bg-overlay-2);
}

.walkthrough-target {
  &:not(.table, .node-wrapper) {
    @include add-overlay(8);
    background-color: var(--color-bg);
    transition: all 0.3s;
  }
  &.table > * {
    @include add-overlay(8);
    background-color: var(--color-bg);
    transition: all 0.3s;
  }
  border-radius: 4px !important;
}

.separator {
  width: 1px;
  height: 24px;
  background-color: var(--color-border);
}

.cdk-drag-preview-carousel-item {
  display: flex;
  gap: 8px;
  font-size: 12px;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--color-bg);
  width: 462px;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// Dimensions extension overrides.
.fn-noCursor {
  z-index: 1000001;
}

.fn-dimensions {
  z-index: 1000000;
}

.fn-axis {
  background: var(--color-primary);
}

.warning-icon {
  width: 20px;
  margin-top: 2px;
  display: flex;
  justify-content: center;

  .icon {
    @include flex-center;
    @include size(16px);
    background: rgba(var(--yellow-600-rgb), 0.2);
    border-radius: 50%;
    font-size: 12px;
    text-align: center;
    color: var(--yellow-600);
    cursor: context-menu;
  }
}

.danger-icon {
  width: 20px;
  .icon {
    @include flex-center;
    @include size(20px);
    background: rgba(var(--red-600-rgb), 0.25);
    border-radius: 50%;
    font-size: 16px;
    text-align: center;
    color: var(--color-danger-secondary);
    cursor: context-menu;
  }
}

as-split .as-split-gutter {
  background-color: var(--color-bg) !important;
}
.report-container {
  height: calc(100vh - 124px);

  iframe {
    border: none;
  }
}

.tooltip-main-color {
  color: var(--color-text-tooltip);
}

.tooltip-secondary-color {
  color: var(--color-text-secondary);
}

@include when-scheme-global('light') {
  @include meta.load-css('../../../../node_modules/highlight.js/styles/github.css');
}
@include when-scheme-global('dark') {
  @include meta.load-css('../../../../node_modules/highlight.js/styles/github-dark.css');
}
pre {
  code {
    &.hljs {
      background-color: var(--color-bg-secondary) !important;
    }
  }
}

.empty-state {
  .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: var(--color-text-primary);
    margin-bottom: 8px;
  }
  .sub-title {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: var(--color-text-secondary);
  }
  .small-title {
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
    color: var(--color-text-primary);
    margin-bottom: 8px;
  }
  .small-sub-title {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--color-text-secondary);
  }
  .top-image {
    width: 300px;
    height: 200px;
    display: block;
    margin: 0 auto 24px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .small-top-image {
    width: 170px;
    height: 112px;
    display: block;
    margin: 0 auto 24px;

    img {
      height: 100%;
      width: 100%;
    }
  }
  key-binding {
    margin: 0 4px;
    .keyboard-shortcut {
      .key.read-only {
        padding: 1.5px 6px;
        height: initial;
      }
    }
  }
}
