@font-face {
  font-family: 'chase-icons';
  src: url('fonts/chase-icons.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'chase-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-toggle-preview:before {
  content: '\e949';
}
.icon-extension:before {
  content: '\e94b';
}
.icon-experiences:before {
  content: '\ea51';
}
.icon-arrow-right-window:before {
  content: '\ea47';
}
.icon-arrow_right:before {
  content: '\ea4c';
}
.icon-close-window:before {
  content: '\ea48';
}
.icon-max-window:before {
  content: '\ea49';
}
.icon-minimize-window:before {
  content: '\ea4a';
}
.icon-arrow-left-window:before {
  content: '\ea4b';
}
.icon-slack-workspace:before {
  content: '\ea38';
}
.icon-signin:before {
  content: '\ea33';
}
.icon-user-permission:before {
  content: '\ea34';
}
.icon-tools:before {
  content: '\ea35';
}
.icon-chrome:before {
  content: '\ea46';
}
.icon-screen:before {
  content: '\ea37';
}
.icon-pin-chrome:before {
  content: '\e9be';
}
.icon-neutral:before {
  content: '\ea1f';
}
.icon-assertive1:before {
  content: '\ea21';
}
.icon-confident:before {
  content: '\ea20';
}
.icon-send_fill:before {
  content: '\ea23';
}
.icon-stop:before {
  content: '\ea13';
}
.icon-send:before {
  content: '\ea12';
}
.icon-tree-collapse:before {
  content: '\ea14';
}
.icon-tree-expended:before {
  content: '\ea15';
}
.icon-dots-horizontal:before {
  content: '\ea0f';
}
.icon-teams-channel:before {
  content: '\ea0e';
}
.icon-teams:before {
  content: '\ea0d';
}
.icon-number-3:before {
  content: '\ea27';
}
.icon-number-4:before {
  content: '\ea28';
}
.icon-number-5:before {
  content: '\ea29';
}
.icon-number-6:before {
  content: '\ea2a';
}
.icon-number-7:before {
  content: '\ea2b';
}
.icon-number-8:before {
  content: '\ea2c';
}
.icon-number-9:before {
  content: '\ea2d';
}
.icon-number-10:before {
  content: '\ea2e';
}
.icon-number-2:before {
  content: '\ea26';
}
.icon-number-1:before {
  content: '\ea25';
}
.icon-all-queries:before {
  content: '\ea1a';
}
.icon-link:before {
  content: '\ea09';
}
.icon-unlink:before {
  content: '\ea0c';
}
.icon-language:before {
  content: '\ea53';
}
.icon-improve2:before {
  content: '\ea54';
}
.icon-improve:before {
  content: '\ea55';
}
.icon-quick_action:before {
  content: '\e945';
}
.icon-line-rounded1:before {
  content: '\ea52';
}
.icon-stop-square:before {
  content: '\ea24';
}
.icon-card:before {
  content: '\e9cc';
}
.icon-copy-3:before {
  content: '\e9cd';
}
.icon-like:before {
  content: '\e9d7';
}
.icon-dis-like:before {
  content: '\e9d9';
}
.icon-draft:before {
  content: '\ea1e';
}
.icon-file-key:before {
  content: '\ea10';
}
.icon-playback-speed:before {
  content: '\ea06';
}
.icon-pic-in-pic:before {
  content: '\ea08';
}
.icon-double-arrow:before {
  content: '\e951';
}
.icon-assertive:before {
  content: '\ea22';
}
.icon-ai-chat:before {
  content: '\ea4d';
}
.icon-chat-dots:before {
  content: '\ea1d';
}
.icon-short:before {
  content: '\ea17';
}
.icon-long:before {
  content: '\ea18';
}
.icon-professional:before {
  content: '\ea19';
}
.icon-all_queries:before {
  content: '\ea1c';
}
.icon-medium:before {
  content: '\ea1b';
}
.icon-lot-of-cards:before {
  content: '\ea16';
}
.icon-folder-full:before {
  content: '\ea11';
}
.icon-api-key:before {
  content: '\ea36';
}
.icon-wiki:before {
  content: '\ea04';
}
.icon-page:before {
  content: '\ea03';
}
.icon-verify:before {
  content: '\e9f7';
}
.icon-align-left:before {
  content: '\e9ff';
}
.icon-align-center:before {
  content: '\ea00';
}
.icon-new-chat:before {
  content: '\e967';
}
.icon-gpt:before {
  content: '\e9fe';
}
.icon-branch:before {
  content: '\ea2f';
}
.icon-create-widget:before {
  content: '\e9ef';
}
.icon-slack1:before {
  content: '\ea30';
}
.icon-tag-label:before {
  content: '\e9fd';
}
.icon-workspace:before {
  content: '\e9f5';
}
.icon-slack:before {
  content: '\e9ee';
}
.icon-Dis-like:before {
  content: '\e9de';
}
.icon-contribution:before {
  content: '\e9fa';
}
.icon-answer-star:before {
  content: '\e9ec';
}
.icon-answer:before {
  content: '\e9df';
}
.icon-like1:before {
  content: '\e9e9';
}
.icon-unverify:before {
  content: '\e9f9';
}
.icon-request:before {
  content: '\e9f8';
}
.icon-verify1:before {
  content: '\ea07';
}
.icon-assistant:before {
  content: '\e9ed';
}
.icon-Union:before {
  content: '\e9d8';
}
.icon-curated:before {
  content: '\e9d2';
}
.icon-card1:before {
  content: '\e9ce';
}
.icon-database:before {
  content: '\e9b7';
}
.icon-cropper:before {
  content: '\e9f1';
}
.icon-table:before {
  content: '\e9e0';
}
.icon-database1:before {
  content: '\e9f3';
}
.icon-coordinator:before {
  content: '\e9bd';
}
.icon-recruiter:before {
  content: '\e9bf';
}
.icon-comment:before {
  content: '\e9c0';
}
.icon-fork:before {
  content: '\e9c1';
}
.icon-commit:before {
  content: '\e9c2';
}
.icon-size:before {
  content: '\e9c3';
}
.icon-code1:before {
  content: '\e9c4';
}
.icon-notebook1:before {
  content: '\e9c6';
}
.icon-not-helpful:before {
  content: '\e9c7';
}
.icon-Helpful:before {
  content: '\e9c8';
}
.icon-github-milestone:before {
  content: '\e9bc';
}
.icon-strudel:before {
  content: '\e9bb';
}
.icon-category:before {
  content: '\e9b9';
}
.icon-buildings:before {
  content: '\e9ba';
}
.icon-edit-frame:before {
  content: '\e9b8';
}
.icon-file-add:before {
  content: '\e9b3';
}
.icon-plus-thick:before {
  content: '\e9b0';
}
.icon-collection:before {
  content: '\e993';
}
.icon-add-collection:before {
  content: '\e996';
}
.icon-live-collection:before {
  content: '\e9b1';
}
.icon-clock:before {
  content: '\e9a6';
}
.icon-gallery-view:before {
  content: '\e9da';
}
.icon-list-view:before {
  content: '\e9db';
}
.icon-ladder:before {
  content: '\e9ab';
}
.icon-exit:before {
  content: '\e9a3';
}
.icon-public-channel:before {
  content: '\e9fb';
}
.icon-private-channel:before {
  content: '\e9fc';
}
.icon-stars:before {
  content: '\e9d6';
}
.icon-circle:before {
  content: '\e9a1';
}
.icon-arrow-circle:before {
  content: '\e9a2';
}
.icon-party:before {
  content: '\e9a0';
}
.icon-slash:before {
  content: '\e9d0';
}
.icon-slash1:before {
  content: '\e9d3';
}
.icon-desktop-light:before {
  content: '\e9a7';
}
.icon-board-list:before {
  content: '\e99a';
}
.icon-windows-full-pin:before {
  content: '\e9cf';
}
.icon-edit-line:before {
  content: '\e9a4';
}
.icon-sync:before {
  content: '\e9a5';
}
.icon-clock2:before {
  content: '\e9a8';
}
.icon-sort-list-down:before {
  content: '\e999';
}
.icon-clock3:before {
  content: '\ea4e';
}
.icon-shareable:before {
  content: '\e992';
}
.icon-tree-folder:before {
  content: '\e98e';
}
.icon-tree-arrow:before {
  content: '\e98f';
}
.icon-curated1:before {
  content: '\ea4f';
}
.icon-list1:before {
  content: '\e9c5';
}
.icon-milestone:before {
  content: '\e9c9';
}
.icon-bucket:before {
  content: '\e9b4';
}
.icon-team:before {
  content: '\e9b5';
}
.icon-duplicate:before {
  content: '\e9ad';
}
.icon-clock1:before {
  content: '\ea50';
}
.icon-puzzle:before {
  content: '\e99f';
}
.icon-delete:before {
  content: '\e98d';
}
.icon-repository:before {
  content: '\e995';
}
.icon-profile:before {
  content: '\e9f6';
}
.icon-account-name:before {
  content: '\e986';
}
.icon-go-links:before {
  content: '\e983';
}
.icon-starred-dark:before {
  content: '\e97d';
}
.icon-starred:before {
  content: '\e97e';
}
.icon-floating_dark:before {
  content: '\e976';
}
.icon-computer_dark:before {
  content: '\e977';
}
.icon-light-mode_dark:before {
  content: '\e978';
}
.icon-dark-mode_dark:before {
  content: '\e979';
}
.icon-floating_light:before {
  content: '\e971';
}
.icon-computer_light:before {
  content: '\e972';
}
.icon-light-mode_light:before {
  content: '\e974';
}
.icon-dark-mode_light:before {
  content: '\e975';
}
.icon-dragging-ind:before {
  content: '\e94a';
}
.icon-job:before {
  content: '\e96f';
}
.icon-shared-link:before {
  content: '\e923';
}
.icon-people-group:before {
  content: '\e905';
}
.icon-light-checkmark:before {
  content: '\e95b';
}
.icon-dark-checkmark:before {
  content: '\e96a';
}
.icon-children:before {
  content: '\e9e1';
}
.icon-parents:before {
  content: '\e9e2';
}
.icon-attachments:before {
  content: '\e9e3';
}
.icon-manufacturer-item-status:before {
  content: '\e9e4';
}
.icon-temoerature:before {
  content: '\e9e5';
}
.icon-orcad:before {
  content: '\e9e6';
}
.icon-NRND:before {
  content: '\e9e7';
}
.icon-automotive:before {
  content: '\e9e8';
}
.icon-manufacturer-item-number:before {
  content: '\e9ea';
}
.icon-manufacturer:before {
  content: '\e9eb';
}
.icon-lock-fill:before {
  content: '\e95a';
}
.icon-manage-plan:before {
  content: '\e93f';
}
.icon-settings-company:before {
  content: '\e946';
}
.icon-check:before {
  content: '\e937';
}
.icon-members:before {
  content: '\e947';
}
.icon-preferences:before {
  content: '\e948';
}
.icon-upgrade:before {
  content: '\e94e';
}
.icon-user1:before {
  content: '\e96e';
}
.icon-lock-light:before {
  content: '\ea44';
}
.icon-lock-dark:before {
  content: '\ea45';
}
.icon-star-solid1:before {
  content: '\e96d';
}
.icon-pause:before {
  content: '\ea0a';
}
.icon-credit-card:before {
  content: '\ea0b';
}
.icon-sad-doc:before {
  content: '\e994';
}
.icon-upload-cloud:before {
  content: '\e9d1';
}
.icon-header:before {
  content: '\ea39';
}
.icon-copy-31:before {
  content: '\ea3a';
}
.icon-emoji-smile:before {
  content: '\e9b2';
}
.icon-filter:before {
  content: '\e9a9';
}
.icon-cards:before {
  content: '\e9aa';
}
.icon-browser-bookmark:before {
  content: '\e9ac';
}
.icon-analytics:before {
  content: '\e99e';
}
.icon-mail-full-circle:before {
  content: '\e990';
}
.icon-spam:before {
  content: '\e988';
}
.icon-file:before {
  content: '\e989';
}
.icon-trash:before {
  content: '\e98a';
}
.icon-snoozed:before {
  content: '\e98b';
}
.icon-important:before {
  content: '\e984';
}
.icon-sent:before {
  content: '\e982';
}
.icon-unleash-logo-text:before {
  content: '\e97c';
}
.icon-agent:before {
  content: '\e981';
}
.icon-unread:before {
  content: '\e980';
}
.icon-paperclip-side:before {
  content: '\e97f';
}
.icon-arrow-right-key:before {
  content: '\e97a';
}
.icon-arrow-left-key:before {
  content: '\e97b';
}
.icon-search-icon-fixed:before {
  content: '\e970';
}
.icon-people-group1:before {
  content: '\e99c';
}
.icon-division:before {
  content: '\e95c';
}
.icon-department:before {
  content: '\e95d';
}
.icon-report:before {
  content: '\e95e';
}
.icon-iphone:before {
  content: '\e95f';
}
.icon-location:before {
  content: '\e960';
}
.icon-mail:before {
  content: '\e962';
}
.icon-icon_lock_for_dark:before {
  content: '\e96b';
}
.icon-icon_lock-for_light:before {
  content: '\e96c';
}
.icon-linux:before {
  content: '\e952';
}
.icon-add:before {
  content: '\e93e';
}
.icon-command:before {
  content: '\e940';
}
.icon-edit:before {
  content: '\e941';
}
.icon-lamp:before {
  content: '\e942';
}
.icon-logout:before {
  content: '\e943';
}
.icon-mac:before {
  content: '\e944';
}
.icon-unleash-logo:before {
  content: '\e938';
}
.icon-mac-maximize-plus:before {
  content: '\e931';
}
.icon-mac-unpin1:before {
  content: '\e908';
}
.icon-mac-pin1:before {
  content: '\e91e';
}
.icon-mac-unmaximize:before {
  content: '\e921';
}
.icon-mac-maximize:before {
  content: '\e924';
}
.icon-mac-minimize:before {
  content: '\e925';
}
.icon-mac-close:before {
  content: '\e926';
}
.icon-windows-unpin:before {
  content: '\e927';
}
.icon-windows-pin:before {
  content: '\e928';
}
.icon-windows-minimize:before {
  content: '\e929';
}
.icon-windows-unmaximize:before {
  content: '\e92b';
}
.icon-windows-maximize:before {
  content: '\e92c';
}
.icon-Windows-close:before {
  content: '\e92d';
}
.icon-duo-exclamation-circle .path1:before {
  content: '\e915';
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.icon-duo-exclamation-circle .path2:before {
  content: '\e916';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-chevron-up:before {
  content: '\e913';
}
.icon-chevron-down:before {
  content: '\e914';
}
.icon-double-chevron-right:before {
  content: '\e912';
}
.icon-arrow-right:before {
  content: '\e911';
}
.icon-external-link:before {
  content: '\e964';
}
.icon-envelop-notification:before {
  content: '\e910';
}
.icon-times-circle:before {
  content: '\e90e';
}
.icon-check-circle:before {
  content: '\e90f';
}
.icon-lightning:before {
  content: '\e906';
}
.icon-star-solid:before {
  content: '\e901';
}
.icon-key-enter:before {
  content: '\e900';
}
.icon-times-large:before {
  content: '\e904';
}
.icon-times-circle1:before {
  content: '\e92f';
}
.icon-share1:before {
  content: '\e91c';
}
.icon-apps:before {
  content: '\e92e';
}
.icon-key-enter1:before {
  content: '\e954';
}
.icon-mac-white:before {
  content: '\e902';
}
.icon-document-music:before {
  content: '\e930';
}
.icon-notebook:before {
  content: '\e91a';
}
.icon-note:before {
  content: '\e959';
}
.icon-chat:before {
  content: '\e956';
}
.icon-star1:before {
  content: '\e966';
}
.icon-bubble1:before {
  content: '\e958';
}
.icon-data:before {
  content: '\e9b6';
}
.icon-chevron-down1:before {
  content: '\e93a';
}
.icon-info:before {
  content: '\e939';
}
.icon-windows-ringer:before {
  content: '\e932';
}
.icon-windows-questions-mark:before {
  content: '\e935';
}
.icon-offline:before {
  content: '\e91d';
}
.icon-copy:before {
  content: '\e963';
}
.icon-times:before {
  content: '\e903';
}
.icon-sign-bleed:before {
  content: '\e90b';
}
.icon-cog2:before {
  content: '\e673';
}
.icon-star:before {
  content: '\e68d';
}
.icon-paper-plane:before {
  content: '\e69a';
}
.icon-eye-crossed:before {
  content: '\e6a6';
}
.icon-file-empty:before {
  content: '\e6b3';
}
.icon-folder:before {
  content: '\e6ba';
}
.icon-book2:before {
  content: '\e714';
}
.icon-calendar-empty:before {
  content: '\e785';
}
.icon-launch:before {
  content: '\e7b0';
}
.icon-bubble-dots:before {
  content: '\e7d8';
}
.icon-gift:before {
  content: '\e7ff';
}
.icon-cube:before {
  content: '\e874';
}
.icon-alarm:before {
  content: '\e8aa';
}
.icon-history2:before {
  content: '\e8e4';
}
.icon-download2:before {
  content: '\e8f5';
}
.icon-code:before {
  content: '\e919';
}
.icon-magnifier:before {
  content: '\e922';
}
.icon-plus:before {
  content: '\e936';
}
.icon-arrow-up-right:before {
  content: '\e94c';
}
.icon-arrow-right-circle:before {
  content: '\e961';
}
.icon-chevron-right-circle:before {
  content: '\e965';
}
.icon-arrow-right-square1:before {
  content: '\ea3b';
}
.icon-chevron-right-square:before {
  content: '\e998';
}
.icon-ellipsis-h-alt:before {
  content: '\ea3c';
}
.icon-pencil5:before {
  content: '\e613';
}
.icon-cloud-crossed:before {
  content: '\e65b';
}
.icon-flag:before {
  content: '\e690';
}
.icon-mailbox-full:before {
  content: '\e693';
}
.icon-envelope-open:before {
  content: '\e697';
}
.icon-paperclip:before {
  content: '\e698';
}
.icon-paper-plane1:before {
  content: '\e699';
}
.icon-inbox2:before {
  content: '\e69d';
}
.icon-file-empty1:before {
  content: '\e6b4';
}
.icon-camera:before {
  content: '\e6ff';
}
.icon-label:before {
  content: '\e717';
}
.icon-contacts:before {
  content: '\e71a';
}
.icon-portrait:before {
  content: '\e71c';
}
.icon-users:before {
  content: '\e722';
}
.icon-calendar-text:before {
  content: '\e78b';
}
.icon-keyboard:before {
  content: '\e791';
}
.icon-window:before {
  content: '\e7b2';
}
.icon-power-switch:before {
  content: '\e7b8';
}
.icon-network:before {
  content: '\e886';
}
.icon-redo2:before {
  content: '\e8d9';
}
.icon-file-image:before {
  content: '\e90d';
}
.icon-link2:before {
  content: '\e917';
}
.icon-thumbs-up:before {
  content: '\e92a';
}
.icon-cross:before {
  content: '\e934';
}
.icon-minus:before {
  content: '\e94f';
}
.icon-chevron-left:before {
  content: '\e93b';
}
.icon-chevron-right:before {
  content: '\e93c';
}
.icon-tab:before {
  content: '\e950';
}
.icon-warning:before {
  content: '\e955';
}
.icon-focus:before {
  content: '\e973';
}
.icon-pencil-line:before {
  content: '\ea3d';
}
.icon-exclamation-mark-circle:before {
  content: '\e907';
}
.icon-sort-down:before {
  content: '\e909';
}
.icon-sort-equal:before {
  content: '\e90a';
}
.icon-user-circle:before {
  content: '\e90c';
}
.icon-folder-plus:before {
  content: '\e6bc';
}
.icon-paperclip1:before {
  content: '\e69b';
}
.icon-cube1:before {
  content: '\e875';
}
.icon-magnifier1:before {
  content: '\e98c';
}
.icon-flag1:before {
  content: '\e691';
}
.icon-tag:before {
  content: '\e755';
}
.icon-calendar-full:before {
  content: '\e789';
}
.icon-home:before {
  content: '\e600';
}
.icon-pencil51:before {
  content: '\e614';
}
.icon-vector:before {
  content: '\e61a';
}
.icon-lock:before {
  content: '\e66b';
}
.icon-envelope:before {
  content: '\e696';
}
.icon-eye:before {
  content: '\e6a5';
}
.icon-copy1:before {
  content: '\e6b8';
}
.icon-register:before {
  content: '\e6d1';
}
.icon-document:before {
  content: '\e6d8';
}
.icon-music-note:before {
  content: '\e6e4';
}
.icon-music-note2:before {
  content: '\e6e5';
}
.icon-playlist:before {
  content: '\e6e7';
}
.icon-play:before {
  content: '\e6f7';
}
.icon-picture:before {
  content: '\e70e';
}
.icon-bookmark:before {
  content: '\e715';
}
.icon-user:before {
  content: '\e71e';
}
.icon-users1:before {
  content: '\e724';
}
.icon-users2:before {
  content: '\e723';
}
.icon-telephone:before {
  content: '\e76a';
}
.icon-pushpin:before {
  content: '\e778';
}
.icon-map-marker:before {
  content: '\e77a';
}
.icon-calendar-check:before {
  content: '\e786';
}
.icon-keyboard1:before {
  content: '\e792';
}
.icon-desktop:before {
  content: '\e7af';
}
.icon-launch1:before {
  content: '\e7b1';
}
.icon-new-tab:before {
  content: '\e7b3';
}
.icon-alarm1:before {
  content: '\e8ab';
}
.icon-bullhorn:before {
  content: '\e8ae';
}
.icon-clock31:before {
  content: '\e8e8';
}
.icon-upload2:before {
  content: '\e8f6';
}
.icon-link21:before {
  content: '\e918';
}
.icon-share:before {
  content: '\e91f';
}
.icon-share2:before {
  content: '\e920';
}
.icon-zoom-in:before {
  content: '\e93d';
}
.icon-question:before {
  content: '\e933';
}
.icon-expand:before {
  content: '\e99d';
}
.icon-contract:before {
  content: '\e94d';
}
.icon-expand2:before {
  content: '\e957';
}
.icon-contract2:before {
  content: '\e968';
}
.icon-warning1:before {
  content: '\e969';
}
.icon-question-circle:before {
  content: '\e997';
}
.icon-chevron-left-square:before {
  content: '\e99b';
}
.icon-menu-square:before {
  content: '\ea42';
}
.icon-city:before {
  content: '\e60b';
}
.icon-apartment:before {
  content: '\e60c';
}
.icon-eye-dropper:before {
  content: '\e625';
}
.icon-palette:before {
  content: '\e626';
}
.icon-magic-wand:before {
  content: '\e62b';
}
.icon-database-refresh:before {
  content: '\e661';
}
.icon-server:before {
  content: '\e666';
}
.icon-shield-alert:before {
  content: '\e669';
}
.icon-unlock:before {
  content: '\e66d';
}
.icon-key:before {
  content: '\e66e';
}
.icon-key-hole:before {
  content: '\e66f';
}
.icon-flag3:before {
  content: '\e692';
}
.icon-at-sign:before {
  content: '\e695';
}
.icon-inbox:before {
  content: '\e69c';
}
.icon-box:before {
  content: '\e69f';
}
.icon-eye-crossed1:before {
  content: '\e6a7';
}
.icon-floppy-disk:before {
  content: '\e6ae';
}
.icon-file-lock:before {
  content: '\e6b6';
}
.icon-files:before {
  content: '\e6b7';
}
.icon-graduation-hat:before {
  content: '\e6da';
}
.icon-time-lapse:before {
  content: '\e702';
}
.icon-book:before {
  content: '\e712';
}
.icon-user-lock:before {
  content: '\e721';
}
.icon-tag1:before {
  content: '\e756';
}
.icon-pushpin2:before {
  content: '\e779';
}
.icon-lamp1:before {
  content: '\e7bc';
}
.icon-chart-bars:before {
  content: '\e7fc';
}
.icon-chart-settings:before {
  content: '\e7fd';
}
.icon-puzzle1:before {
  content: '\e876';
}
.icon-history21:before {
  content: '\e8e5';
}
.icon-Close-panel-left:before {
  content: '\e8f9';
}
.icon-open-panel-right:before {
  content: '\e8fa';
}
.icon-file-code:before {
  content: '\e9ae';
}
.icon-file-preview:before {
  content: '\e9af';
}
.icon-file-spreadsheet:before {
  content: '\e9dc';
}
.icon-zoom-out:before {
  content: '\e991';
}
.icon-list:before {
  content: '\e987';
}
.icon-checkmark-circle:before {
  content: '\e9cb';
}
.icon-crop:before {
  content: '\e9f0';
}
.icon-grid:before {
  content: '\e9dd';
}
.icon-layers:before {
  content: '\e9ca';
}
.icon-arrow-up-square:before {
  content: '\ea05';
}
.icon-arrow-right-square:before {
  content: '\e9f2';
}
.icon-text-align-left:before {
  content: '\ea01';
}
.icon-text-align-center:before {
  content: '\ea02';
}
.icon-sort-alpha-asc:before {
  content: '\e9d4';
}
.icon-sort-alpha-desc:before {
  content: '\e9d5';
}
.icon-solid-ellipsis-h:before {
  content: '\e91b';
}
.icon-bin-solid:before {
  content: '\ea3e';
}
.icon-plus-solid:before {
  content: '\ea3f';
}
.icon-minus-solid:before {
  content: '\ea40';
}
.icon-arrow-down-solid:before {
  content: '\ea41';
}
.icon-share-solid:before {
  content: '\ea7d';
}
.icon-windows:before {
  content: '\eac2';
}
.icon-warning2:before {
  content: '\ea43';
}
.icon-whatsapp:before {
  content: '\ea93';
}
.icon-copy2:before {
  content: '\e953';
}
.icon-hash:before {
  content: '\e985';
}
